<template>
  <div class="main">
    <TableMain
      tableTitle="Accidentes y Enfermedades Laborales"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Registrar Accidente o Enfermedad"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="[
        '#',
        'Tipo de Reporte',
        'Cédula del Empleado',
        'Nombre del Empleado',
        'Fecha del Reporte',
        'Días de Incapacidad',
        'PCL'
      ]"
      :data="employees"
      :editOption="true"
      :deleteOption="true"
      :printOption="true"
      @editAction="editEvent($event)"
      @deleteAction="deleteEvent($event)"
      @transferAction="transferEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";

import { prettyID, prettyDate } from "@/modules/tools";

export default {
  components: { Legal, TableMain },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Empleados / Accidentes Y Enfermedades / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    async editEvent(_id) {
      const store = new Storage();
      const accidents = await store.getData("accident");
      const diseases = await store.getData("disease");
      accidents.forEach(accident => {
        if (accident._id == _id) {
          this.$router.push({
            name: "Employee AT Edit Accident",
            params: {
              currentId: _id,
              _id: this.$route.params._id
            }
          });
        }
      });
      diseases.forEach(disease => {
        if (disease._id == _id) {
          this.$router.push({
            name: "Employee AT Edit Disease",
            params: {
              currentId: _id,
              _id: this.$route.params._id
            }
          });
        }
      });
    },
    deleteAccident(_id) {
      const store = new Storage();
      Swal.fire({
        title: "¿Eliminar Reporte de Accidente?",
        showCancelButton: true,
        confirmButtonText: `Eliminar`,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d"
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Eliminando Reporte de Accidente",
            html:
              "Por favor espere mientras se elimina el reporte</b> " +
              '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            stopKeydownPropagation: true
          });
          store.deleteData("optima", "accident", _id).then(() => {
            Swal.fire({
              icon: "success",
              title: "Reporte de Accidente Eliminado",
              text: "Todos los datos han sido sincronizados"
            }).then(() => {
              this.$router.go();
            });
          });
        }
      });
    },
    deleteDisease(_id) {
      const store = new Storage();
      Swal.fire({
        title: "¿Eliminar Reporte de Enfermedad?",
        showCancelButton: true,
        confirmButtonText: `Eliminar`,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d"
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Eliminando Reporte de Enfermedad",
            html:
              "Por favor espere mientras se elimina el reporte</b> " +
              '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            stopKeydownPropagation: true
          });
          store.deleteData("optima", "disease", _id).then(() => {
            Swal.fire({
              icon: "success",
              title: "Reporte de Enfermedad Eliminado",
              text: "Todos los datos han sido sincronizados"
            }).then(() => {
              this.$router.go();
            });
          });
        }
      });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("accident", _id).then(accident => {
        if (accident) {
          this.deleteAccident(_id);
        } else {
          this.deleteDisease(_id);
        }
      });
    }
  },
  async created() {
    const store = new Storage();
    const employees = await store.getData("employee");
    const accidents = await store.getData("accident");
    const diseases = await store.getData("disease");

    accidents.forEach(async accident => {
      if (accident.branch === this.$route.params._id) {
        let currentEmployee;
        employees.forEach(employee => {
          if (accident.employee == employee._id) {
            currentEmployee = employee;
          }
        });
        this.employees.push([
          accident._id,
          "Accidente",
          await prettyID(currentEmployee.ID),
          currentEmployee.name,
          await prettyDate(accident.accidentDate),
          accident.daysOfDisability,
          accident.PCL + "%"
        ]);
      }
    });

    diseases.forEach(async disease => {
      if (disease.branch === this.$route.params._id) {
        let currentEmployee;
        employees.forEach(employee => {
          if (disease.employee == employee._id) {
            currentEmployee = employee;
          }
        });
        this.employees.push([
          disease._id,
          "Enfermedad",
          await prettyID(currentEmployee.ID),
          currentEmployee.name,
          await prettyDate(disease.diseaseDate),
          disease.daysOfDisability,
          disease.PCL + "%"
        ]);
      }
    });
  },
  data() {
    return {
      employees: []
    };
  },
  name: "Employee Evaluation Branch"
};
</script>
